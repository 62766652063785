<template>
  <div id="print"><h1 style="text-align: center; font-weight: 400">上海奥吉实业有限公司</h1>
    <h3 style="text-align: center; font-weight: 800">付款通知单</h3>
    <div>
      <div style="display: flex;width: calc(100% - 40px);margin-bottom:15px;">
        <div style="flex:1">
          <label v-for="item in paymentList" :key="item.value">
            <input v-if="item.value === data[0].detail.pay_method" type="checkbox" name="payment" :value="item.name" :checked="true">
            <input v-else type="checkbox" name="payment" :value="item.name" :checked="false">
            {{ item.name }}
          </label>
        </div>
        付款单位:
        <span class="custom-style" style="width: 200px;margin-right:10px;">{{ type }}</span>
        <span class="custom-style">{{ year }}</span>年
        <span class="custom-style">{{ month }}</span>月
        <span class="custom-style">{{ date }}</span>日
      </div>
    </div>
    <div>
      <div style="display:flex;">
        <table style="flex:1;">
          <tbody>
            <tr>
              <th rowspan="2" style="text-align: center">摘要</th>
              <th colspan="9" style="text-align: center">金额</th>
            </tr>
            <tr>
              <td>佰</td>
              <td>拾</td>
              <td>万</td>
              <td>仟</td>
              <td>佰</td>
              <td>拾</td>
              <td>元</td>
              <td>角</td>
              <td>分</td>
            </tr>
            <tr v-for="item in data" :key="item.id">
              <td>
                <div style="display:flex;justify-content:space-between;">费用:{{ item.fee_name }} 付款对象:{{ item.pay_target_name }}<span
                  class="show">币种:{{ item.currency_name }}</span></div>
              </td>
              <td>{{ getFeeDigit(item.money.toFixed(2)[item.money.toFixed(2).length - 10]) }}</td>
              <td>{{ getFeeDigit(item.money.toFixed(2)[item.money.toFixed(2).length - 9]) }}</td>
              <td>{{ getFeeDigit(item.money.toFixed(2)[item.money.toFixed(2).length - 8]) }}</td>
              <td>{{ getFeeDigit(item.money.toFixed(2)[item.money.toFixed(2).length - 7]) }}</td>
              <td>{{ getFeeDigit(item.money.toFixed(2)[item.money.toFixed(2).length - 6]) }}</td>
              <td>{{ getFeeDigit(item.money.toFixed(2)[item.money.toFixed(2).length - 5]) }}</td>
              <td>{{ getFeeDigit(item.money.toFixed(2)[item.money.toFixed(2).length - 4]) }}</td>
              <td>{{ getFeeDigit(item.money.toFixed(2)[item.money.toFixed(2).length - 2]) }}</td>
              <td>{{ getFeeDigit(item.money.toFixed(2)[item.money.toFixed(2).length - 1]) }}</td>
            </tr>
            <tr>
              <td>
                <div style="display:flex;align-items:center;">
                  <span style="width:60px;" v-if="data[0].currency_name ==='CNY'">
                    <label><input type="checkbox" name="currency" value="人民币" :checked="true">人民币</label>
                    <label><input type="checkbox" name="currency" value="外币">外&emsp;币 </label>
                  </span>
                  <span style="width:60px;" v-else>
                    <label><input type="checkbox" name="currency" value="人民币">人民币</label>
                    <label><input type="checkbox" name="currency" value="外币" :checked="true">外&emsp;币 </label>
                  </span>
                  <span>合计: <span :class="getCurrency(money[money.length - 10])">{{ numberToChinese(getFeeDigit(money[money.length - 10])) }} 佰 </span>
                    <span :class="getCurrency(money[money.length - 9])">{{ numberToChinese(getFeeDigit(money[money.length - 9])) }} 拾 </span>
                    <span :class="getCurrency(money[money.length - 8])">{{ numberToChinese(getFeeDigit(money[money.length - 8])) }} 万 </span>
                    <span :class="getCurrency(money[money.length - 7])">{{ numberToChinese(getFeeDigit(money[money.length - 7])) }} 仟 </span>
                    <span :class="getCurrency(money[money.length - 6])">{{ numberToChinese(getFeeDigit(money[money.length - 6])) }} 佰 </span>
                    <span :class="getCurrency(money[money.length - 5])">{{ numberToChinese(getFeeDigit(money[money.length - 5])) }} 拾 </span>
                    <span :class="getCurrency(money[money.length - 4])">{{ numberToChinese(getFeeDigit(money[money.length - 4])) }} 元 </span>
                    <span :class="getCurrency(money[money.length - 2])">{{ numberToChinese(getFeeDigit(money[money.length - 2])) }} 角 </span>
                    <span :class="getCurrency(money[money.length - 1])">{{ numberToChinese(getFeeDigit(money[money.length - 1])) }} 分 </span>
                  </span>
                </div>
              </td>
              <td>{{ getFeeDigit(money[money.length - 10]) }}</td>
              <td>{{ getFeeDigit(money[money.length - 9]) }}</td>
              <td>{{ getFeeDigit(money[money.length - 8]) }}</td>
              <td>{{ getFeeDigit(money[money.length - 7]) }}</td>
              <td>{{ getFeeDigit(money[money.length - 6]) }}</td>
              <td>{{ getFeeDigit(money[money.length - 5]) }}</td>
              <td>{{ getFeeDigit(money[money.length - 4]) }}</td>
              <td>{{ getFeeDigit(money[money.length - 2]) }}</td>
              <td>{{ getFeeDigit(money[money.length - 1]) }}</td>
            </tr>
          </tbody>
        </table>
        <!-- <span style="display: inline-block;width: 30px;padding-left:10px;">
          <p>附件</p>
          <h2>{{ data[0].file_paper }}</h2>
          <p>纸</p>
        </span> -->
      </div>
    </div>
    <p id="footer">
      <span>总经理：</span>
      <span>财务经理：</span>
      <span>分管领导：</span>
      <span v-if="type === '国贸部'">部门经理：</span>
      <span v-else-if="type === '客服部'">部门经理：张丽青</span>
      <span v-else-if="type === '贸易部'">部门经理：张海新</span>
      <span v-else>部门经理：</span>
      <span>经办人：{{ userInfo.name }}</span></p>
  </div>
</template>

<script>
import moment from 'moment'
import { numberToChinese } from '@/utils/util'
export default {
  name: 'PayBill',
  components: {},
  props: {
    orderData: {
      type: Array,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  created() {
    this.initData()
  },
  watch: {
    type(val, oldVal) {
      this.type = val
    },
    orderData: {
      handler(val, oldVal) {
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        }
      ],
      data: [],
      userInfo: this.$store.getters.userInfo,
      year: moment().year(),
      month: moment().month() + 1,
      date: moment().date(),
      money: 0
    }
  },
  methods: {
    numberToChinese,
    initData() {
      let money = 0
      this.orderData.forEach(item => {
        money += item.money
      })
      this.money = money.toFixed(2) + ''
      this.data = this.orderData
    },
    getCurrency(s) {
      if (s === undefined || '') {
        return 'hidden'
      } else {
        return 'show'
      }
    },
    getFeeDigit(s) {
      if (s) {
        return s
      } else {
        return ' '
      }
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}
@media print {
  html, body {
    height: inherit;
  }
}
.title{
  width: 100%;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
#print { width: 80%; margin: 0 auto; padding-top: 50px }
#print p, #print table { width: 100% }
table { border-collapse: collapse }
td, th { padding: 5px; border: 1px solid #ccc; }
th:first-of-type { width:80% }
th:last-of-type { width:20% }
#footer span { display: inline-block; width: 19% }
.custom-style{display: inline-block;width: 50px;border-bottom: 1px solid #cccccc;text-align: center;}
.show{display: inline;}
.hidden{display: none;}
</style>

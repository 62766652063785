<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务类型">
              <a-select v-model="queryParam['type']"
                        style="width: 100%" @change="$refs.table.refresh(true)">
                <a-select-option :key="3">全部</a-select-option>
                <a-select-option :key="0">进口</a-select-option>
                <a-select-option :key="1">清关</a-select-option>
                <a-select-option :key="2">出口</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单编号">
              <a-input v-model="queryParam['%serial_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="进库核注编号">
              <a-input v-model="queryParam['%check_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="出库核注编号">
              <a-input v-model="queryParam['%clear_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="录单时间">
              <a-range-picker @change="onDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="客服员">
              <a-select
                showSearch
                allowClear
                placeholder="请选择客服员"
                style="width: 100%"
                v-model="queryParam['%customerId']">
                <a-select-option v-for="op in customerInfos" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用状态">
              <a-select
                v-model="queryParam['fee_status']"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
              >
                <a-select-option v-for="o in this.GLOBAL.feeStatusMaps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款对象">
              <a-select
                v-model="queryParam['pay_target_id']"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
              >
                <a-select-option v-for="o in this.supplierOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <!--      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleVerifyAccount('check')">对账</a-button>-->
      <!--      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleVerifyAccount('uncheck')">反对账</a-button>-->
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleAudit('basic_audit')">审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleAudit('undo_audit')">反审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleApplyBizAudit()">商务审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleInvoiceNum()">填写发票号</a-button>
      <a-button type="primary" @click="handleExport">导出</a-button>
    </div>
    <s-table
      ref="table"
      size="default"
      :rowKey="(record, index) => {
        return record.serial_num
      }"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 'max-content' }"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange, onSelect: onSelect }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="choosePayTarget(record, '付款单')">付款单</a>
        </template>
        <a-divider type="vertical" />
        <template>
          <a @click="choosePayTarget(record, '付款对账单')">付款对账单</a>
        </template>
      </span>
      <div slot="feeItem" slot-scope="text, record">
        <div v-for="item in record.fees" :key="item.id">
          <a-popover trigger="click" v-if="item.deny_reason">
            <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
              {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
            </a-tag>
            <template slot="content">
              <div v-for="(reason, index) in item.deny_reason" :key="index">
                <div>驳回人:<a style="margin-right:10px;">{{ reason.deny_person_name }}</a>驳回原因:<a style="margin-right:10px;">{{ reason.deny_reason }}</a>驳回时间:<a>{{ reason.deny_date }}</a></div>
              </div>
            </template>
          </a-popover>
          <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;" v-else>
            {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
          </a-tag>
          <span>{{ item.pay_target_name }} </span>
          <span>{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
        </div>
      </div>
    </s-table>
    <a-modal
      :visible="applyFormVisible" :confirmLoading="confirmLoading"
      title="商务审核申请" :width="720"
      @cancel="applyFormVisible = false"
      @ok="handleApplyFormSubmit"
    >
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-divider orientation="left">基础信息</a-divider>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="付款方式" prop="pay_method">
              <a-select v-model="form.pay_method">
                <a-select-option :value="item.value" v-for="item in paymentList" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="附件数" prop="appendix_num">
              <a-input suffix="张" v-model="form.appendix_num"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="发票号码" prop="invoice_number">
          <a-select
            showSearch
            mode="multiple"
            ref="invoice_number"
            :filter-option="filterOption"
            allowClear
            placeholder="请选择发票号码"
            style="width: 100%"
            v-model="form.invoice_num"
          >
            <a-select-option v-for="op in invoiceRegOps" :key="op.id"
            >发票号:{{ op.invoice_number }}/金额:{{ op.amount }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <!--        <a-form-model-item label="发票号">-->
        <!--          <a-input v-model="form.invoice_num" />-->
        <!--        </a-form-model-item>-->
        <a-divider orientation="left">费用信息
          <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
        </a-divider>
        <a-checkbox
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChange"
          style="padding-bottom:10px;"
        >
          全选
        </a-checkbox>
        <a-row>
          <a-checkbox-group v-model="checkedList" @change="onChange" style="width:100%;">
            <a-row v-for="item in feeOrderList" :key="item.id">
              <a-alert :message="`订单编号:${item.orderId}`" type="info" show-icon style="margin-bottom:10px;" />
              <a-col :span="8" v-for="fee in item.feeList" :key="fee.id" style="padding-left:15px;padding-bottom:10px;">
                <a-checkbox :value="fee.id"> {{ fee.pay_target_name }}{{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }} </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-row>
        <a-form-model-item label="申请备注" prop="apply_remark">
          <a-textarea v-model="form.apply_remark"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      :visible="applyInvoiceNumFormVisible" :confirmLoading="confirmInvoiceNumLoading"
      title="后补发票号" :width="720"
      @cancel="applyInvoiceNumFormVisible = false"
      @ok="handleApplyInvoiceNumFormSubmit"
    >
      <a-form-model layout="vertical" :model="invoiceNumForm"
                    :rules="invoiceNumFormRules" ref="invoiceNumForm">
        <a-divider orientation="left">基础信息</a-divider>
        <a-form-model-item label="发票号">
          <a-input v-model="invoiceNumForm.invoiceNum" />
        </a-form-model-item>
        <a-divider orientation="left">费用信息
          <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
        </a-divider>
        <a-checkbox
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChange"
          style="padding-bottom:10px;"
        >
          全选
        </a-checkbox>
        <a-row>
          <a-checkbox-group v-model="checkedList" @change="onChange" style="width:100%;">
            <a-row v-for="item in feeOrderList" :key="item.id">
              <a-alert :message="`订单编号:${item.orderId}`" type="info" show-icon style="margin-bottom:10px;" />
              <a-col :span="8" v-for="fee in item.feeList" :key="fee.id" style="padding-left:15px;padding-bottom:10px;">
                <a-checkbox :value="fee.id"> {{ fee.pay_target_name }}{{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }}/发票号:{{ fee.invoiceNum }} </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-row>
      </a-form-model>
    </a-modal>
    <!--选择付款对象-->
    <a-modal
      :visible="choosePayTargetVisible"
      :confirmLoading="choosePayTargetVisibleLodding"
      title="付款对象"
      :width="720"
      @cancel="choosePayTargetCancel"
      @ok="subPrintPayBills"
    >
      <a-divider orientation="left">选择付款对象</a-divider>
      <a-row>
        <a-col :span="8">
          <a-radio-group v-model="pay_target_Data">
            <a-radio :key="item.id" v-for="item in payTarget" :value="item">{{ item.pay_target_name }}{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}/发票号:{{ item.invoice_num }}</a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
    </a-modal>

    <a-modal
      :visible="printPayBillModel"
      :confirmLoading="printPayBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printPayBillModel = false"
    >
      <div id="printPayBill">
        <pay-bill
          :orderData="printData"
          :type="orderType"
        >
        </pay-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printPayBillBtn"
                  v-print="printPayBill">确认打印</a-button>
        <a-button type="primary" @click="printPayBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printPayStatementsBillModel"
      :confirmLoading="printPayStatementsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款对账单"
      @cancel="printPayStatementsBillModel = false"
    >
      <div id="printStatementsPayBill">
        <pay-statement-bill
          :orderData="printData"
          :type="orderType"
        >
        </pay-statement-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printStatementsPayBtn"
                  v-print="printStatementsPayBill">确认打印</a-button>
        <a-button type="primary" @click="printPayStatementsBillModel = false">关闭</a-button>
      </template>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getCommonNoPage, getCustomer, getSupplier, downloadExcel } from '@/api/common'
import { filterOption } from '@/utils/util'
import { getCumsFinancePage, CumsFinanceFunc, exportCumsFinance, getCumsMetaOption } from '@/api/cums'
import moment from 'moment'
import debounce from 'lodash/debounce';
import { fmsBizFinanceFunc } from '@/api/fms'
import payBill from '@/views/components/PayBill';
import payStatementBill from '@/views/components/PayStatementBill';
export default {
  components: {
    STable,
    payBill,
    payStatementBill
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      queryParam: {
        type: 3
      },
      customerInfo: undefined,
      customerInfos: [],
      fetching: false,
      customerOps: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '接单日期',
          dataIndex: 'record_time',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD HH:MM:SS')
          }
        },
        {
          title: '订单编号',
          dataIndex: 'serial_num'
        },
        {
          title: '委托客户',
          dataIndex: 'agency_name'
        },
        {
          title: '品名',
          dataIndex: 'product_name'
        },
        {
          title: '客户编号',
          dataIndex: 'customer_num'
        },
        {
          title: '客服员',
          dataIndex: 'customer'
        },
        {
          title: '港区',
          dataIndex: 'port'
        },
        {
          title: '贸易方式',
          dataIndex: 'trade_type_name'
        },
        {
          title: '毛重',
          dataIndex: 'gloss_weight'
        },
        {
          title: '净重',
          dataIndex: 'weight'
        },
        {
          title: '件数',
          dataIndex: 'num'
        },
        {
          title: '总体积',
          dataIndex: 'total_volume'
        },
        {
          title: '提运单号',
          dataIndex: 'delivery_num'
        },
        {
          title: '船名航次',
          dataIndex: 'ship_name'
        },
        {
          title: '箱量',
          dataIndex: 'box_message'
        },
        {
          title: '费用',
          dataIndex: 'cms_fee_list',
          fixed: 'right',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '240px',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      selectedRows: [],
      applyFormVisible: false,
      confirmLoading: false,
      form: {
        pay_method: '',
        appendix_num: null,
        apply_remark: '',
        invoice_num: []
      },
      rules: {
        apply_remark: [
          { required: true, message: '请输入申请备注', trigger: 'blur' }
        ],
        pay_method: [
          { required: true, message: '请选择付款方式', trigger: 'change' }
        ]
      },
      loadData: parameter => {
        return getCumsFinancePage('pay_list', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      totalAmount: '',
      indeterminate: false,
      checkAll: true,
      checkedList: [],
      feeOrderList: [],
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        }
      ],
      applyInvoiceNumFormVisible: false,
      confirmInvoiceNumLoading: false,
      invoiceNumForm: {
        invoiceNum: null
      },
      invoiceNumFormRules: {
        invoiceNum: [
          { required: true, message: '请输入发票号', trigger: 'blur' }
        ]
      },
      choosePayTargetVisible: false,
      choosePayTargetVisibleLodding: false,
      payTarget: [],
      pay_target_Data: {},
      orderData: {},
      userInfo: this.$store.getters.userInfo,
      payBillName: '',
      invoiceRegOps: [],
      supplierOps: [],
      printPayStatementsBillModel: false,
      printPayStatementsBillModelLoading: false,
      printStatementsPayBill: {
        id: 'printStatementsPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayBillModel: false,
      printPayBillModelLoading: false,
      printPayBill: {
        id: 'printPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printData: [],
      orderType: '客服部'
    }
  },
  created() {
    getCumsMetaOption('cums_meta_info', { type: 7 }).then(v => {
      this.customerInfos = v;
    });
    getSupplier({ department: 7 }).then(res => {
      this.supplierOps = res
    })
  },
  methods: {
    onDateChange(date, dateString) {
      if (date[0]) {
        const _date = [date[0].startOf('day').valueOf(), date[1].endOf('day').valueOf()]
        this.queryParam['@record_time'] = _date
      } else {
        this.queryParam['@record_time'] = []
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 7,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        // 委托客户选择框中无值
        this.customerInfo = undefined;
        this.queryParam['auth_agency'] = null;
      } else {
        // 委托客户选择框中有值
        this.customerInfo = value;
        this.queryParam['auth_agency'] = value.key;
      }
      this.customerOps = []
      this.fetching = false
    },
    filterOption,
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    onSelect (record, selected, selectedRows) {
      if (selected) {
        this.selectedRows.push(record);
      }

      if (!selected) {
        const delIndex = this.selectedRows.findIndex(val => {
          return val.serial_num === record.serial_num
        })
        this.selectedRows.splice(delIndex, 1);
      }
    },
    handleVerifyAccount(operate) {
      const data = []
      let flag = true
      this.selectedRows.forEach(v => {
        if (operate === 'check') {
          if (v.pay_status === 0) {
            data.push(v.id)
          } else {
            flag = false
          }
        } else {
          if (v.pay_status === 1) {
            data.push(v.id)
          } else {
            flag = false
          }
        }
      })
      if (flag) {
        CumsFinanceFunc(operate, {
          ids: this.selectedRowKeys,
          type: 2
        }, this.queryParam.type).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          })
          this.selectedRowKeys = []
          this.selectedRows = []
          this.$refs.table.refresh(false)
        })
      } else {
        this.$message.warning(operate === 'check' ? '请选择未对账的数据' : '请选择已对账待审核的数据')
      }
    },
    handleAudit(operate) { // 业务审核
      const data = [];
      this.selectedRows.forEach(v => {
        v.fees.forEach(fee => {
          switch (operate) {
            // 审核
            case 'basic_audit':
              if (fee.fee_status === 1) data.push(v.id);
              break;
            // 未审核
            case 'undo_audit':
              if (fee.fee_status === 2) data.push(v.id);
              break;
          }
        });
      });
      if (data.length > 0) {
        CumsFinanceFunc(operate, {
          type: 2,
          ids: data
        }).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          });
          this.selectedRowKeys = [];
          this.selectedRows = [];
          this.$refs.table.refresh(false);
        });
      } else {
        this.$message.warning(operate === 'basic_audit' ? '请选择已对账待审核的数据' : '请选择业务已审核的数据');
      }
    },
    handleApplyBizAudit() { // 提交商务审核
      // 提交商务审核
      let flag = false
      this.checkedList = []
      this.totalAmount = ''
      const feeMaps = []
      const map = new Map()
      this.selectedRows.forEach(v => {
        const orderFee = {
          orderId: v.serial_num,
          feeList: v.fees && v.fees.filter(e => e.fee_status === 2 || e.fee_status === 4).map(e => {
              if (map.has(e.currency_name)) {
                map.set(e.currency_name, e.money + map.get(e.currency_name))
              } else {
                map.set(e.currency_name, e.money)
              }
              if (e.fee_status === 2 || e.fee_status === 4) {
                flag = true
              }
              this.checkedList.push(e.id)
              return {
                id: e.id,
                feeName: e.fee_name,
                money: e.money,
                currency_name: e.currency_name,
                pay_target_name: e.pay_target_name
              }
            })
        }
        feeMaps.push(orderFee)
      })
      if (flag) {
        for (const key of map.keys()) {
          this.totalAmount += map.get(key).toFixed(2) + key + ','
        }
        this.feeOrderList = feeMaps
        const data = {}
        getCommonNoPage('invoice_registration', data).then((v) => {
          this.invoiceRegOps = v
        })
        this.applyFormVisible = true
      } else {
        this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
      }
    },
    handleInvoiceNum() {
      // 提交发票号
      this.checkedList = []
      this.totalAmount = ''
      const feeMaps = []
      const map = new Map()
      this.selectedRows.forEach(v => {
        const orderFee = {
          orderId: v.serial_num,
          feeList: v.fees && v.fees.map(e => {
            if (map.has(e.currency_name)) {
              map.set(e.currency_name, e.money + map.get(e.currency_name))
            } else {
              map.set(e.currency_name, e.money)
            }
            this.checkedList.push(e.id)
            return {
              id: e.id,
              feeName: e.fee_name,
              money: e.money,
              currency_name: e.currency_name,
              invoiceNum: e.invoice_num,
              pay_target_name: e.pay_target_name
            }
          })
        }
        feeMaps.push(orderFee)
      })
      for (const key of map.keys()) {
        this.totalAmount += map.get(key).toFixed(2) + key + ','
      }
      this.feeOrderList = feeMaps
      this.applyInvoiceNumFormVisible = true
    },
    // 后补发票号
    handleApplyInvoiceNumFormSubmit() {
      this.$refs.invoiceNumForm.validate(valid => {
        if (valid) {
          const data = {
            invoice_num: this.invoiceNumForm.invoiceNum,
            department: 7,
            ids: this.checkedList
          }
          fmsBizFinanceFunc('save_invoice_num', data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            this.selectedRows = []
            this.selectedRowKeys = []
            this.applyInvoiceNumFormVisible = false
            // this.invoiceNumForm.invoiceNum = null
            // this.$refs.invoiceNumForm.resetFields();
            this.$refs.table.refresh(false)
          })
        }
      })
    },
    handleApplyFormSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true;
          CumsFinanceFunc('business_audit', {
            apply_remark: this.form.apply_remark,
            pay_method: this.form.pay_method,
            invoice_num: this.form.invoice_num,
            appendix_num: this.form.appendix_num,
            id_list: this.checkedList
          }, this.queryParam.type).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            this.confirmLoading = false
            this.applyFormVisible = false
            this.selectedRowKeys = []
            this.selectedRows = []
            this.form.invoice_num = []
            // this.$refs.form.resetFields();
            this.$refs.table.refresh(false)
          }).catch(_ => {
            this.confirmLoading = false
          })
        }
      })
    },
    handleExport() {
      this.queryParam.ids = this.selectedRowKeys.join(',')
      exportCumsFinance('export_pay_data', this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    onChange(checkedValues) {
      let count = 0
      this.totalAmount = ''
      const mapItem = new Map()
      for (const item of this.feeOrderList) {
        count += item.feeList.length
        for (let i = 0; i < checkedValues.length; i++) {
          for (let j = 0; j < item.feeList.length; j++) {
            if (checkedValues[i] === item.feeList[j].id) {
              if (mapItem.has(item.feeList[j].currency_name)) {
                 mapItem.set(item.feeList[j].currency_name, item.feeList[j].money + mapItem.get(item.feeList[j].currency_name))
              } else {
                mapItem.set(item.feeList[j].currency_name, item.feeList[j].money)
              }
            }
          }
        }
      }
      for (const key of mapItem.keys()) {
        this.totalAmount += mapItem.get(key).toFixed(2) + key + ','
      }
      this.indeterminate = !!this.checkedList.length && this.checkedList.length < count
      this.checkAll = this.checkedList.length === count
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked
      this.indeterminate = false
      this.checkedList = []
      this.totalAmount = ''
      const mapList = new Map()
      if (e.target.checked) {
        this.feeOrderList.forEach(item => {
          for (const e of item.feeList) {
            if (mapList.has(e.currency_name)) {
              mapList.set(e.currency_name, e.money + mapList.get(e.currency_name))
            } else {
              mapList.set(e.currency_name, e.money)
            }
            this.checkedList.push(e.id)
          }
        })
        for (const key of mapList.keys()) {
          this.totalAmount += mapList.get(key).toFixed(2) + key + ','
        }
      }
    },
    // 选择付款对象
    choosePayTarget(record, payBillName) {
      const data = record.fees
      const fees = data.filter(item => item.fee_status >= 3 && item.fee_status !== 4)
      if (fees.length > 0) {
        this.payTarget = fees
        this.payBillName = payBillName
        this.orderData = record
        this.choosePayTargetVisible = true
      } else {
        this.$message.error('当前订单没有提交商务审核费用')
      }
    },
    // 打印
    subPrintPayBills() {
      if (this.payBillName === '付款单') {
        this.printPayFeeBill()
      } else if (this.payBillName === '付款对账单') {
        this.printPayFeeAccountBill()
      }
    },
    // 选择付款对象关闭
    choosePayTargetCancel() {
      this.pay_target_Data = {}
      this.orderData = {}
      this.payBillName = ''
      this.choosePayTargetVisible = false
    },
    // 打印付款对账单
    printPayFeeAccountBill() {
      const ids = []
      ids.push(this.orderData.id)
      const data = {
        ids: ids,
        pay_target_id: this.pay_target_Data.pay_target_id,
        fee_id: this.pay_target_Data.id
      }
      CumsFinanceFunc('payFeeBill', data).then(v => {
        this.printData = v
        this.printPayStatementsBillModel = true
      })
      //   .finally(() => {
      //   this.billPrint('printStatementsPayBtn')
      //   this.printPayStatementsBillModel = false
      // })
    },
    // 打印付款单
    printPayFeeBill() {
      const ids = []
      ids.push(this.orderData.id)
      const data = {
        ids: ids,
        pay_target_id: this.pay_target_Data.pay_target_id,
        fee_id: this.pay_target_Data.id
      }
      CumsFinanceFunc('payFeeBill', data).then(v => {
        // 费用名称相同的订单进行合并
        const data = v
        const mapItem = new Map()
        const result = []
        data.forEach(fee => {
          if (mapItem.has(fee.fee_name_type)) {
            mapItem.get(fee.fee_name_type).money += fee.money
          } else {
            mapItem.set(fee.fee_name_type, fee)
          }
        })
        for (const key of mapItem.keys()) {
          result.push(mapItem.get(key))
        }
        this.printData = result
        this.printPayBillModel = true
      })
      //   .finally(() => {
      //   this.billPrint('printPayBillBtn')
      //   this.printPayBillModel = false
      // })
    },
    // 打印弹窗
    billPrint(btnName) {
      document.getElementById(btnName).click()
    },
    getCurrency(s) {
      if (s === undefined || '') {
        return 'hidden'
      } else {
        return 'show'
      }
    },
    getFeeDigit(s) {
      if (s) {
        return s
      } else {
        return ' '
      }
    },
    getFeeAmount(s) {
      if (s) {
        return s
      } else {
        return ''
      }
    },
    moneyFormatter(money) {
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    }
  }
}
</script>
